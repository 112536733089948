<template>
  <icon-base :width="size + 'px'" :fill="color">
    <g>
      <path class="st0" d="M187.7,134.7c-2.7-0.1-5.3,0.9-7.4,2.5c-5.3,3.9-18.9,13.5-24.7,14.7c-6.8,1.4-33.1,6.1-46.6-4.5   c-1.9-1.5-1-4.6,1.5-4.8c2.1-0.2,5-0.3,9.1-0.1c7.8,0.5,20.5,1.2,29.4,1.8c5.7,0.4,10.6-3.8,11.2-9.4c0.6-5.8-3.5-11-9.3-11.7   l-28.7-2.6c0,0-13.8-2.6-26.8-10.5c-15.1-9.2-48.6-5.4-62-0.7c-1.2,0.4-1.9,1.5-1.9,2.7v43.3c0,1.5,1.2,2.8,2.7,2.9   c5.2,0.4,17.5,2.5,33.5,12.8c21.3,13.9,30,15.9,42.7,15.9c12.7,0,41-5.4,52.8-7.5c11.8-2.1,31.6-25,34-31   C199,143.6,196.1,134.9,187.7,134.7z"/>
      <path class="st0" d="M22.7,103.1H4.9c-1.4,0-2.5,1.1-2.5,2.5v62.5c0,1.4,1.1,2.5,2.5,2.5h17.9c1.4,0,2.5-1.1,2.5-2.5v-62.5   C25.3,104.2,24.1,103.1,22.7,103.1z M13.8,167c-3.3,0-6-2.7-6-6s2.7-6,6-6c3.3,0,6,2.7,6,6S17.1,167,13.8,167z"/>
      <path class="st0" d="M84.1,69.5c2.3,0.2,4.5,0.6,6.8,0.8c0.4,0.1,0.7,0.2,0.8,0.6c0.8,2.8,1.9,5.5,3.3,8c0.3,0.4,0.1,0.7-0.1,1.1   c-1.5,1.9-3.1,3.9-4.6,5.8c-1.2,1.5-1.1,2.9,0.2,4.2c2.7,2.7,5.4,5.4,8.1,8.2c1.3,1.3,2.7,1.4,4.2,0.2c1.9-1.5,3.9-3,5.8-4.5   c0.5-0.4,0.8-0.4,1.3-0.1c2.5,1.4,5.1,2.5,7.8,3.2c0.5,0.1,0.7,0.3,0.8,0.9c0.2,2.3,0.6,4.5,0.8,6.8c0.1,1.6,0.7,2.7,2.1,3.4   c4.5,0,9,0,13.6,0c1.4-0.6,2.1-1.6,2.3-3.1c0.2-2.3,0.6-4.7,0.8-7c0.1-0.5,0.2-0.8,0.8-0.9c2.8-0.7,5.4-1.8,7.9-3.2   c0.4-0.3,0.7-0.2,1.1,0.1c2,1.6,3.9,3.1,5.9,4.7c1.4,1.1,2.8,1,4.1-0.3c2.8-2.8,5.5-5.5,8.3-8.3c1.2-1.2,1.3-2.7,0.2-4.1   c-1.5-2-3-3.9-4.6-5.8c-0.3-0.4-0.4-0.7-0.1-1.2c1.4-2.5,2.5-5.1,3.3-7.8c0.1-0.5,0.3-0.7,0.9-0.7c2.4-0.3,4.8-0.6,7.2-0.9   c2-0.2,3-1.2,3-3.2c0-3.8,0-7.6,0-11.4c0-1.9-1-3-2.9-3.2c-2.5-0.3-4.9-0.6-7.4-0.9c-0.5-0.1-0.7-0.2-0.8-0.7   c-0.8-2.8-1.9-5.4-3.3-7.9c-0.3-0.5-0.2-0.7,0.1-1.1c1.6-1.9,3.1-3.9,4.6-5.8c1.1-1.4,1.1-2.8-0.2-4.1c-2.7-2.7-5.5-5.5-8.2-8.2   c-1.3-1.3-2.7-1.4-4.1-0.3c-2,1.5-3.9,3-5.8,4.6c-0.4,0.3-0.7,0.4-1.2,0.1c-2.5-1.4-5.1-2.5-7.9-3.2c-0.4-0.1-0.6-0.3-0.7-0.8   c-0.3-2.5-0.6-4.9-0.9-7.4c-0.2-1.9-1.3-2.9-3.2-2.9c-3.8,0-7.7,0-11.5,0c-1.9,0-2.9,1-3.2,2.9c-0.3,2.5-0.6,4.9-0.9,7.4   c0,0.4-0.2,0.6-0.6,0.7c-2.8,0.8-5.5,1.9-8,3.3c-0.4,0.2-0.7,0.2-1.1-0.1c-2-1.6-3.9-3.1-5.9-4.7c-1.4-1.1-2.8-1-4.1,0.2   c-2.8,2.8-5.5,5.5-8.3,8.3c-1.3,1.3-1.3,2.7-0.2,4.1c1.5,2,3.1,4,4.7,5.9c0.3,0.4,0.4,0.7,0.1,1.1c-1.4,2.5-2.5,5.1-3.2,7.9   c-0.2,0.6-0.4,0.7-0.9,0.8c-2.2,0.2-4.5,0.6-6.7,0.8c-1.6,0.1-2.7,0.7-3.4,2.1c0,4.6,0,9.2,0,13.8C81.4,68.8,82.5,69.4,84.1,69.5z    M128.4,36.7c13.1,0,23.7,10.7,23.8,23.8c0,13.1-10.7,23.8-23.8,23.8c-13.1,0-23.8-10.7-23.8-23.8   C104.5,47.4,115.3,36.7,128.4,36.7z"/>
    </g>
  </icon-base>
</template>

<script>
export default {
  name: 'iconConfiguration',
  components: {
    iconBase: () => import('@/common/components/iconBase.vue')
  },
  props: {
    size: {
      type: Number,
      default: 20
    },
    color: {
      type: String,
      default: ''
    },
    enableBackground: {
      type: Boolean
    }
  }
}
</script>

<style scoped>
.st1 {
  fill: #ffffff;
}
.st2 {
  clip-path: url(#SVGID_2_);
}
.st3 {
  fill: #ea3838;
}
.st4 {
  fill: #72bf58;
}
</style>

